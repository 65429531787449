<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The PASS acronym is a useful mnemonic in case you have to use a fire extinguisher. Match the
        first word with the rest of the statement.
      </p>

      <p class="mb-2">
        1st: Pull
        <v-select
          v-model="inputs.input1"
          class="ml-1"
          style="max-width: 400px; display: inline-block"
          :items="items"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        2nd: Aim
        <v-select
          v-model="inputs.input2"
          class="ml-1"
          style="max-width: 400px; display: inline-block"
          :items="items"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        3rd: Squeeze
        <v-select
          v-model="inputs.input3"
          class="ml-1"
          style="max-width: 400px; display: inline-block"
          :items="items"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        4th: Sweep
        <v-select
          v-model="inputs.input4"
          class="ml-1"
          style="max-width: 400px; display: inline-block"
          :items="items"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LCQ3S2Q7',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      items: [
        {text: 'the handle to begin the discharge', value: 'a'},
        {text: 'the extinguisher at the top of the fire', value: 'b'},
        {text: 'the safety pin near the handle', value: 'c'},
        {text: 'the extinguisher toward the fire', value: 'd'},
        {text: "the discharge back and forth horizontally across the fire's base", value: 'e'},
        {text: 'the extinguisher at the base of the fire', value: 'f'},
        {text: 'the safety pin until it breaks in two', value: 'g'},
        {text: 'the discharge up and down vertically from top to bottom on the fire', value: 'h'},
      ],
    };
  },
};
</script>
<style scoped></style>
